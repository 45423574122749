import Vue from "vue";
import Vuex from "vuex";

import mutations from "@/store/mutations";
import getters from "@/store/getters";
import menu from "@/router/menu"
import organization from "@/store/actions/organization";
import user from "@/store/actions/user";
import project from "@/store/actions/project";
import device from "@/store/actions/device";
import detectedObjects from "@/store/actions/detected-objects";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        //Menu for layout
        menu:menu,
        //User data from cognito
        user: null,
        organizations:[],
        projects:[],
        installationProject: null,
        users:[],
        projectHealthData:[],
        allTests: [],
        socketState:{
            connected: false
        },
        deviceRecordings:[],
        ocrMessages:[],
        objectPath: null,
        detectedObjects:[]
    },
    mutations: mutations,
    getters: getters,
    actions: {...organization, ...user, ...project,...device, ...detectedObjects}
});
