<template>
<div></div>
</template>

<script>
export default {
  name: "socket-component",
  data(){
    return{
      connected: false,
    }
  },
  mounted() {

  },
  beforeDestroy() {
    if(this.socket) {
      this.socket.disconnect();
    }
  },
  methods:{
    createConnection(){
      if(this.$store.state.user && !this.connected){
        this.socket = this.$socketIo.io(process.env.VUE_APP_SOCKET_ENDPOINT, {
          auth: {
            token: `Bearer ${this.$store.state.user.signInUserSession.idToken.jwtToken}`
          }
        });
        console.log(`Connecting socket...`);

        this.socket.on("connect", () => {
          this.connected = true
          this.$store.commit('setSocketState', this.connected)
          console.log(this.socket.id);
        });

        this.socket.on("disconnect", () => {
          this.connected = false
          console.log('Disconnected', this.socket.id) // undefined
          this.$store.commit('setSocketState', this.connected)
        });

        this.socket.on('cam_time', (data) => {
          //console.log(data);
          let newData = {
            type: 'latency',
            data: data
          }
          this.$emit('messageReceived', newData)
        });

        this.socket.on('object_paths', (data) => {
          //console.log(data);
          let newData = {
            type: 'object_paths',
            data: data
          }
          this.$emit('messageReceived', newData)
        });

        this.socket.on(process.env.VUE_APP_SOCKET_ADMIN_EVENTS, (data) => {
          //console.log('Admin event received: ', data);
          this.$emit('messageReceived', data)
        });

        this.socket.on(process.env.VUE_APP_SOCKET_PROJECT_EVENTS, (data) => {
          //console.log('Project event received: ', data);
          this.$emit('messageReceived', data)
        });

        if (this.socket) this.socket.emit('chat', 'Test');
      }
    }
  }
}
</script>

<style scoped>

</style>