//Vue & Vuex
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/default_store'
//Map import
import VueMapbox from 'vue-mapbox'
import Mapbox from 'mapbox-gl'
//Amplify (Auth)
import {Amplify, Auth} from 'aws-amplify'
//Axios
import Axios from 'axios'
//Buefy import
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
//SCSS Custom theme
import './main.scss'
//import VideoPlayer from 'vue-video7'
import 'video.js/dist/video-js.css'
import  SocketIo  from 'socket.io-client';
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment';

//Vue.use(moment);

Vue.prototype.$moment = moment
console.log('%c Real time traffic is the owner of this project, it is not safe to modify the content of the page.!', 'color: red; font-size: 30px; font-weight: bold;');

/**
 * Global HTTP
 * @type {AxiosStatic}
 */
Vue.prototype.$http = Axios
/**
 * Global Auth
 * @type {AuthClass}
 */
Vue.prototype.$auth = Auth

/**
 * Access to ENV file
 * @type {{readonly VUE_APP_API_URL?: string, readonly VUE_APP_TITLE?: string, readonly VUE_APP_ACCESS_TOKEN?: string, readonly NODE_ENV?: string, readonly VUE_APP_SIGN_UP_RECAPTCHA_KEY?: string, readonly VUE_APP_IDENTITY_POOL_ID?: string, readonly VUE_APP_REGION?: string, readonly VUE_APP_USER_POOL_WEB_CLIENT_ID?: string, readonly VUE_APP_USER_POOL_ID?: string, readonly PATH?: string, readonly __CFBundleIdentifier?: string, readonly SHELL?: string, readonly CONDA_DEFAULT_ENV?: string, readonly CONDA_EXE?: string, readonly OLDPWD?: string, readonly CONDA_PYTHON_EXE?: string, readonly USER?: string, readonly TMPDIR?: string, readonly COMMAND_MODE?: string, readonly SSH_AUTH_SOCK?: string, readonly CONDA_PREFIX?: string, readonly _CE_CONDA?: string, readonly XPC_FLAGS?: string, readonly _CE_M?: string, readonly __CF_USER_TEXT_ENCODING?: string, readonly CONDA_PROMPT_MODIFIER?: string, readonly LOGNAME?: string, readonly LC_CTYPE?: string, readonly XPC_SERVICE_NAME?: string, readonly HOME?: string, readonly CONDA_SHLVL?: string, [p: string]: string}}
 */
Vue.prototype.$env = process.env
/**
 * Amplify configuration
 */
Amplify.configure({
    Auth: {
        identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID, // example: 'us-east-2:c85f3c18-05fd-4bb5-8fd1-e77e7627a99e'
        region: process.env.VUE_APP_REGION, // example: 'us-east-2'
        userPoolId: process.env.VUE_APP_USER_POOL_ID, // example: 'us-east-2_teEUQbkUh'
        userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID, // example: '3k09ptd8kn8qk2hpk07qopr86'
    },
})

Vue.prototype.$socketIo = SocketIo;

Vue.prototype.$moment = moment

/**
 * Interceptors
 * If user is loged in and API url start with RTT API url automatic add
 * JWT token
 */
Vue.prototype.$http.interceptors.request.use(
    (config) => {
        try {
            const isWsApiUrl = config.url.startsWith(process.env.VUE_APP_WSA_API_URL)
            if(isWsApiUrl){
                return Vue.prototype.$auth.currentAuthenticatedUser().then((user) => {
                    config.headers['Authorization'] = `Bearer ${user.signInUserSession.idToken.jwtToken}`
                    console.log('Config: ', config)
                    console.log('User: ', user)
                    return config
                })
            }else{
                return config
            }

        } catch (e) {
            return config
        }
    },
    (error) => {
        return Promise.reject(error)
    },
)

Vue.prototype.$http.interceptors.response.use((response) => {
    console.log('Response', response)
    const isWsApiUrl = response.config.url.startsWith(process.env.VUE_APP_WSA_API_URL)
    if(isWsApiUrl){
        console.log('Include api Url')
        if(response.status === 401){
            Vue.prototype.$auth.signOut().then(() => {
                router.push('/')
                this.isLoading = false;
            })
        }
    }
    return response;
}, (error) => {
    throw error;
});

Vue.config.productionTip = true

/**
 * Vue enable plugins
 */
Vue.use(VueMapbox, {mapboxgl: Mapbox})
Vue.use(Buefy)
Vue.use(VueApexCharts)
Vue.component('apex-chart', VueApexCharts)
//Vue.use(VideoPlayer)



/**
 * Vue start
 */
new Vue({
    store,
    router,
    render: (h) => h(App),
}).$mount('#app')
