<template>
  <div class="app">
    <socket-component @messageReceived="socketMessageReceived" ref="socket"></socket-component>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <sidebar v-if="layout === true"/>
    <LayoutDefault :layout="layout" :layoutMenu="layoutMenu" style="width: 100%">
      <router-view
          @updateLayout="updateLayout"
          @showLoading="showLoading"
          @showMessage="showMessage"
          v-slot="{ Component }">
        <transition :name="transitionName">
          <component :is="Component" />
        </transition>
      </router-view>
    </LayoutDefault>
  </div>
</template>

<script>
import LayoutDefault from './layouts/default/default';
import Sidebar from './components/side-bar/side-bar.vue'
import SocketComponent from "@/components/socket-component/socket-component.vue";


const SENDER = {
  id: "123",
  name: "John Doe",
};

export default {
  name: 'Home-Page',
  components: {
    SocketComponent,
    LayoutDefault,
    Sidebar
  },
  data() {
    return {
      layout: false,
      layoutMenu: [],
      isLoading: false,
      transitionName: 'slide-right',
    }
  },
  async mounted() {
      this.$auth.currentAuthenticatedUser().then((user) => {
        this.$store.commit('saveUserData', user)
        this.$refs.socket.createConnection()
      });
  },
  methods: {
    updateLayout(data) {
      this.layout = data;
    },
    showLoading(show) {
      this.isLoading = show
    },
    showMessage(message, success) {
      this.$buefy.toast.open({
        message: message,
        type: success ? 'is-success' : 'is-danger'
      })
    },
    socketMessageReceived(data) {
      //console.log('Socket message received: ', data)
      if(data.type && data.type === 'latency'){
        this.$store.commit('addLatencyMessage', data.data.message)
      }else if(data.type && data.type === 'object_paths'){
        console.log('Message received from paths: ', data)
        this.$store.commit('setObjectPath', data.data.message)
      }
    }
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    },
  },
};
</script>

<style lang="scss">
:root {
  --primary: #fff;
  --primary-alt: #d5d5d5;
  --grey: #64748b;
  --dark: #18AA7E;
  --dark-alt: #15946e;
  --light: #f3f3f3;
  --sidebar-width: 340px;
}
body {
  background: var(--light);
}
button {
  cursor: pointer;
  appearance: none;
  border: none;
  outline: none;
  background: none;
}
.app {
  display: flex;
  background: var(--light);
}
</style>
